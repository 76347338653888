import React from 'react'
import { ThemeContext } from '../containers/layout'

class ReadMore extends React.Component {
  constructor({ initialHeight }) {
    super()
    this.state = { initialHeight, maxHeight: initialHeight }
  }

  toggle() {
    let { maxHeight, initialHeight } = this.state

    //if expanded, close it
    if (maxHeight !== initialHeight) return this.setState({ maxHeight: initialHeight })

    let height = this.calculateHeight()

    //set the full height
    this.setState({ maxHeight: height })
  }

  calculateHeight() {
    //calculate height of all the children
    let children = [...this.container.children]
    let height = 0
    children.forEach(child => (height += child.offsetHeight))

    return height
  }

  componentDidMount() {
    if (this.calculateHeight() <= this.props.initialHeight)
      this.setState({ hideReadMore: true })
  }

  render() {
    let { children, readMore, blurStyle, overhangSize } = this.props
    let { maxHeight, initialHeight, hideReadMore } = this.state
    let open = maxHeight !== initialHeight

    return (
      <ThemeContext.Consumer>
        {themeMode =>
          console.log({ themeMode }) || (
            <React.Fragment>
              <div
                className="readmore container"
                style={{
                  maxHeight: open ? maxHeight : maxHeight - overhangSize,
                  transition: 'max-height .5s ease',
                  position: 'relative',
                  overflow: 'hidden',
                }}
                ref={el => (this.container = el)}
              >
                {children}
                {hideReadMore ? null : (
                  <div
                    className="readmore overhang"
                    style={{
                      transition: 'opacity 0.25s',
                      opacity: open ? 0 : 1,
                      backgroundImage:
                        themeMode === 'light'
                          ? 'linear-gradient(to bottom, rgba(247, 248, 250, .04), rgba(247, 248, 250, 1.0) )'
                          : 'linear-gradient(to bottom,rgb(36, 39, 41,.04), rgba(36, 39, 41, 1.0) )',
                      content: '',
                      height: `${overhangSize}px`,
                      width: '100%',
                      position: 'absolute',
                      bottom: '0',
                      left: '0',
                      ...blurStyle,
                    }}
                  />
                )}
              </div>

              {hideReadMore
                ? null
                : readMore({
                    open,
                    onClick: () => this.toggle(),
                  })}
            </React.Fragment>
          )
        }
      </ThemeContext.Consumer>
    )
  }
}

ReadMore.defaultProps = {
  overhangSize: 160,
}

export default ReadMore
