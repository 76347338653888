// extracted by mini-css-extract-plugin
export var form = "form-ui-module--form--59abb";
export var formField = "form-ui-module--formField--33f47";
export var formWrap = "form-ui-module--formWrap--12c87";
export var inlineRadio = "form-ui-module--inlineRadio--dad14";
export var inputField = "form-ui-module--inputField--6ad57";
export var required = "form-ui-module--required--e3776";
export var selectFieldWrap = "form-ui-module--selectFieldWrap--d6de7";
export var selectIcon = "form-ui-module--selectIcon--72e3a";
export var submitBtn = "form-ui-module--submitBtn--06370";
export var svg = "form-ui-module--svg--84fd9";