import React from 'react'
import FormUI from './form-ui'
import fetchRequest from '../lib/fetch-request'

function Form(props) {
  const { submitButtonText, formFields, formSlug, title, formPageId } = props
  console.log({ formSlug })
  const [submitting, setSubmitting] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const onSubmit = async (values, { target }) => {
    const body = JSON.stringify({ fieldValues: { ...values }, formSlug, formPageId }, null, 2)
    if (target?.reportValidity?.() === false) return
    if (!formPageId) throw new Error('missing formPage _id')

    try {
      setSubmitting(true)
      const result = await fetchRequest('sanity-api', { method: 'POST', body })
      console.log(result)
      setSuccess(true)
      setSubmitted(true)
    } catch (e) {
      console.log(e)
      setSuccess(false)
      setSubmitted(true)
      setSubmitting(false)
    }
  }

  const submittedStyle = {
    backgroundColor: success ? '#C6F6D5' : '#FEB2B2',
    color: success ? '#2F855A' : 'red',
    margin: '0 0 2rem 0',
    padding: '2rem 4rem 2rem .5rem',
    direction: 'rtl',
  }

  return (
    <React.Fragment>
      <FormUI
        submitButtonText={submitButtonText}
        formFields={formFields}
        onSubmit={onSubmit}
        disabled={submitting}
      />
      {submitted === true && (
        <div style={submittedStyle}>
          <p data-testid="submissionMsg" style={{ maxWidth: '660px', margin: '0 auto' }}>
            {success ? 'تم التسجيل بنجاح' : 'حدث خطأ الرجاء المحاولة لاحقا'}
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

export default Form
