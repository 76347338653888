const fetchRequest = (apiName, options) => {
  const isString = typeof apiName === 'string'
  const enoughLength = typeof apiName === 'string' && apiName.length > 2
  if (!isString || !enoughLength) return Promise.reject('invalid (lambda) function name')
  const backSlash = apiName.startsWith('/') ? '' : '/'
  const fullUrl = '/.netlify/functions' + backSlash + apiName
  console.log(fullUrl)
  return fetch(fullUrl, options).then(
    res => (res.ok ? res.json() : Promise.reject(res)) // ERROR handling highly depends on your api
  )
}

export default fetchRequest
