import React from 'react'
import { useForm } from 'react-hook-form'
import standardSlugify from 'standard-slugify'
import Container from './../components/container'
import * as styles from './form-ui.module.css'

function FormUI(props) {
  const { register, handleSubmit, errors } = useForm()
  const { onSubmit, formFields, submitButtonText, disabled = false } = props

  //because preact incompitable with react-hook-form for checkbox and radio
  const customRegister = (name, options) => {
    const { ref, ...field } = register(name, options)

    const customRef = (...args) => {
      setTimeout(() => ref(...args))
    }

    return { ...field, ref: customRef }
  }

  //todo: use register({required:true}) // to use custom validations

  if (!formFields) return <div>...</div>

  function inputTypeForTextField(field) {
    // console.log(JSON.stringify(field, null, 2).substr(0, 150) + '...')

    const required =
      (field && field.required === 'true') || (field && field.required === true) ? true : false

    const name = standardSlugify(field.label.trim())
    const labelEl = (
      <label htmlFor={name}>
        {field.label_ar}
        {required ? <span className={styles.required}>*</span> : null}
      </label>
    )
    const legendEl = (
      <legend>
        {field.description}
        {required ? <span className={styles.required}>*</span> : null}
      </legend>
    )

    if (field.type === 'string') {
      return (
        <div key={field._key} className={styles.formField}>
          {labelEl}
          <input
            {...register(name)}
            required={required}
            type="text"
            id={name}
            className={styles.inputField}
          />
        </div>
      )
    } else if (field.type === 'textarea') {
      return (
        <div key={field._key} className={styles.formField}>
          {labelEl}
          <input
            {...register(name, required)}
            type="textarea"
            id={name}
            required={required}
            className={styles.inputField}
          />
        </div>
      )
    } else if (field.type === 'email') {
      return (
        <div key={field._key} className={styles.formField}>
          {labelEl}
          <input
            {...register(name, required)}
            type="email"
            required={required}
            id={name}
            className={styles.inputField}
          />
        </div>
      )
    } else if (field.type === 'tel') {
      return (
        <div key={field._key} className={styles.formField}>
          {labelEl}
          <input
            {...register(name, required)}
            type="text"
            inputMode="numeric"
            pattern="[0-9\s()+]*"
            id={name}
            required={required}
            className={styles.inputField}
          />
        </div>
      )
    } else if (field.type === 'select') {
      return (
        <div key={field._key} className={styles.formField}>
          {labelEl}
          <div className={styles.selectFieldWrap}>
            <select
              {...register(name, required)}
              type="select"
              id={name}
              className={styles.inputField}
            >
              {field.items.map((item, ind) => (
                <option key={`${name}_select_${ind}`} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <div className={styles.selectIcon}>
              <svg
                className={styles.svg}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      )
    } else if (field.type === 'radio') {
      return (
        <div key={field._key} className={styles.formField}>
          {labelEl}
          <div className={styles.inlineRadio}>
            {field.items.map((item, ind) => {
              let inputLabel = item
              let inputValue = item

              if (item.split(';;').length > 1) [inputLabel, inputValue] = item.split(';;')

              return (
                <div key={`${name}_radio_${field._key}_${ind}`}>
                  <input
                    {...customRegister(name, required)}
                    type="radio"
                    id={`${name}-${inputLabel}`}
                    required={required}
                    value={inputValue}
                  />
                  <label htmlFor={`${name}-${inputLabel}`}>{inputLabel}</label>
                </div>
              )
            })}
          </div>
          {legendEl}
        </div>
      )
    } else if (field.type === 'checkbox') {
      return (
        <fieldset key={field._key} className={styles.formField}>
          {legendEl}
          {field.items.map((item, ind) => (
            <div key={`${name}_checkbox_${ind}`}>
              <input
                {...customRegister(name, required)}
                type="checkbox"
                className="sr-only"
                id={`${name}-${item}`}
                value={item}
              />
              <label htmlFor={`${name}-${item}`} className="flex items-center cursor-pointer">
                {/* Used for the checkmark graphics */}
                <span />
                {item}
              </label>
            </div>
          ))}
        </fieldset>
      )
    }
  }

  return (
    <div className={styles.formWrap}>
      <Container>
        <form
          data-testid="registrationForm"
          id="registrationForm"
          className={styles.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <fieldset form="registrationForm" disabled={disabled}>
            {formFields.map(field => inputTypeForTextField(field))}
            <button className={styles.submitBtn} type="submit">
              {submitButtonText || 'Register'}
            </button>
          </fieldset>
        </form>
      </Container>
    </div>
  )
}

export default FormUI
