// extracted by mini-css-extract-plugin
export var base = "registration-module--base--318aa";
export var blockQuote = "registration-module--blockQuote--35b55";
export var formHeader = "registration-module--formHeader--af49e";
export var large = "registration-module--large--55918";
export var micro = "registration-module--micro--46fba";
export var microCustom = "registration-module--microCustom--199a2";
export var paragraph = "registration-module--paragraph--d9d7e";
export var readMore = "registration-module--readMore--b685b registration-module--large--55918";
export var responsiveTitle1 = "registration-module--responsiveTitle1--aad4a";
export var responsiveTitle2 = "registration-module--responsiveTitle2--bc892";
export var responsiveTitle3 = "registration-module--responsiveTitle3--25953";
export var responsiveTitle4 = "registration-module--responsiveTitle4--d05db";
export var small = "registration-module--small--007bb";
export var smallCustom = "registration-module--smallCustom--0c3ad";
export var title = "registration-module--title--1c911 registration-module--responsiveTitle1--aad4a";
export var title1 = "registration-module--title1--20683";
export var title2 = "registration-module--title2--3a783 registration-module--base--318aa";
export var title3 = "registration-module--title3--99b5d";