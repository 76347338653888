import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../containers/layout'
import PortableText from '../components/portableText'
import SEO from '../components/seo'
import Container from './../components/container'
import Form from '../components/Form'
import ReadMore from './../lib/react-read-more'
import * as styles from './registration.module.css'
import Breadcrumb from '../components/layout/breadcrumb'

// Non static query, see $id
export const query = graphql`
  query($_id: String!) {
    sanityFormPage(_id: { eq: $_id }) {
      title
      _rawDetail
      _rawForm
    }
  }
`

const RegistrationPage = ({ data, location, pageContext } = {}) => {
  const { _id, slug } = pageContext
  const {
    title: title = '',
    _rawDetail,
    _rawForm: {
      formFields: formFields = null,
      submitButtonText: submitButtonText = null,
    } = {},
  } = data && data.sanityFormPage
  let { navItemStack = [] } = location ? location.state || {} : []
  if (navItemStack.length)
    navItemStack.push({
      name: title,
      slug: `registration/${slug}`,
    })

  return (
    <React.Fragment>
      <SEO title={title} description={`نموذج التسجيل - ${title}`} />
      <Layout>
        <Breadcrumb currentNavItems={navItemStack} />
        <Container>
          <div className={styles.formHeader}>
            <h2 className={styles.title}>{title}</h2>
            <ReadMore
              initialHeight={390}
              readMore={props => (
                <button className={styles.readMore} onClick={props.onClick}>
                  {props.open ? 'عرض أقل ᎗' : 'المزيد ⌵'}
                </button>
              )}
            >
              {_rawDetail && <PortableText blocks={_rawDetail} />}
            </ReadMore>
          </div>
          {formFields && slug && (
            <div className="mt-12">
              <Form
                submitButtonText={submitButtonText}
                title={title}
                formFields={formFields}
                formSlug={slug}
                formPageId={_id}
                submitButtonText={submitButtonText}
              />
            </div>
          )}
        </Container>
      </Layout>
    </React.Fragment>
  )
}
export default RegistrationPage
